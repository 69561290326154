import Header from './Header.js';
import Nav from './Nav.js';
import ContactForm from './ContactForm.js';
import Footer from './Footer.js';
import './App.css';

export default function Contact() {
  return (
    <div className='Home'>
      <Header />
      <Nav />
      <ContactForm />
      <Footer />
    </div>
  );
}

